const EMPTY_MESSAGE = ' '

const checkboxRequireValidator = (_, value, callback) => {
  if (!value) {
    callback(new Error())
  }
  callback()
}

const checkboxRequireValidatorRules = [
  {
    validator: checkboxRequireValidator,
    trigger: 'change',
    message: EMPTY_MESSAGE
  }
]

export {
  checkboxRequireValidatorRules
}
