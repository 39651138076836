import i18n from '../../plugins/i18n'

const PASSWORD_MIN_LENGTH = 8

const passwordSymbolValidator = (_, value, callback) => {
  if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/.test(value)) {
    callback(new Error(`
    ${i18n.t('validators.password.symbols')}
    `))
  }
  callback()
}

const minLengthValidator = (minLength) => {
  
  return (_, value, callback) => {
    if (value.length < minLength) {
      callback(new Error(`
        ${i18n.t('validators.password.min_length', {count: minLength})}
        `)
      )
    }
    callback()
  }
}

const passwordValidatorRules = [
  {
    validator: passwordSymbolValidator,
    trigger: 'change',
  },
  {
    validator: minLengthValidator(PASSWORD_MIN_LENGTH),
    trigger: 'change',
  },
]

export {
  passwordValidatorRules
}
