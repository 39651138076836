<template>
  <el-card v-loading="isLoading" shadow="never">
    <template #header>
      <auth-card-header :heading="$t('page_register.form_title')"/>
    </template>
    <el-form
        :rules="formRules"
        size="medium"
        status-icon
        :model="form"
        ref="registerForm"
    >
      <el-form-item prop="firstName">
        <el-input
            :maxlength="$options.INPUT_MAX_LENGTH"
            :placeholder="$t('forms.first_name')"
            v-model="form.firstName" type="text"
        />
      </el-form-item>
      <el-form-item prop="lastName">
        <el-input
            :maxlength="$options.INPUT_MAX_LENGTH"
            :placeholder="$t('forms.last_name')"
            v-model="form.lastName" type="text"
        />
      </el-form-item>
      <el-form-item prop="email">
        <el-input :placeholder="$t('forms.email')" v-model="form.email" type="email"/>
      </el-form-item>
      <el-form-item
          prop="password"
      >
        <el-input
            :maxlength="$options.INPUT_MAX_LENGTH"
            :placeholder="$t('forms.password')"
            v-model="form.password" type="password"
        />
      </el-form-item>
      <el-form-item
          class="mb-0"
          prop="isRegulations"
      >
        <el-checkbox v-model="form.isRegulations">
          <div class="d-flex flex-wrap agreement-text">
            <span class="field-star text--danger">*</span>
            {{ $t('page_register.terms_text_1') }}
            <el-link
                :href="$options.TERM_OF_REGULATION_DOCUMENT"
                :underline="false"
                type="primary"
                class="innertext"
            >{{ $t('page_register.terms_link_text') }}
            </el-link>
          </div>
        </el-checkbox>
      </el-form-item>
      <el-form-item
          class="mb-0"
          prop="isCommercialInformation"
      >
        <el-checkbox name="commercialInformation" v-model="form.isCommercialInformation">
          <div class="d-flex agreement-text">
            <span class="checkbox-text">{{ $t('page_register.commercial_information_text') }}</span>
          </div>
        </el-checkbox>
        <div class="text-center">
          <el-link
              :underline="false"
              type="primary"
              class="ebejshij-link"
              :href="`pdf/${$t('page_register.register_info_pdf')}`"
          >
            {{ $t('page_register.register_info_link') }}
          </el-link>
        </div>
      </el-form-item>
      <el-form-item class="mt-1">
        <v-wide-button size="large" type="primary" @click.native.prevent="handleRegister">
          {{ $t('page_register.btn_submit') }}
        </v-wide-button>
      </el-form-item>
      <v-alert ref="alert"/>
      <el-form-item class="mb-0">
        <div class="link-wrapper">
          <span class="innertext">Lub</span>
          <el-link @click="$router.push({name: 'login'})" :underline="false" type="primary">
            {{ $t('page_register.login_link') }}
          </el-link>
        </div>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import * as backgroundImage from '../../../assets/images/bg-register.jpg'

import VWideButton from '../../components/ui/v-wide-button/VWideButton'
import VAlert from '../../components/ui/v-alert/VAlert'

import {registerUser} from '../shared/services/auth'
import {passwordValidatorRules} from '../../shared/validators/passwordValidatorRules'
import {checkboxRequireValidatorRules} from '../../shared/validators/checkboxRequireValidatorRule'
import {TERM_OF_REGULATION_DOCUMENT} from '../../shared/constants/app-documents'

import {mapState} from 'vuex'
import AuthCardHeader from '../shared/components/auth-card-header/AuthCardHeader'

export default {
  name: 'AuthRegister',
  TERM_OF_REGULATION_DOCUMENT,
  components: {
    AuthCardHeader,
    VAlert,
    VWideButton
  },
  INPUT_MAX_LENGTH: 255,
  inject: ['changeLayoutImage'],
  created() {
    this.changeLayoutImage(backgroundImage)
  },
  data() {
    return {
      formRules: {
        firstName: {
          required: true, message: this.$t('validators.field_required')
        },
        lastName: {
          required: true, message: this.$t('validators.field_required')
        },
        email: [
          {required: true, message: this.$t('validators.field_required')},
          {type: 'email', message: this.$t('validators.incorrect_email')}
        ],
        password: passwordValidatorRules,
        isRegulations: checkboxRequireValidatorRules,
      },
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        isRegulations: false,
        isCommercialInformation: false,
      }
    }
  },
  computed: {
    ...mapState('loadingState', {
      isLoading: state => state.isLoading
    }),
  },
  methods: {
    handleRegister() {
      this.$refs.registerForm.validate((valid) => {
        if (!valid) return
        registerUser(this.form).then(() => {
          this.$refs.alert.showAlert({
            type: 'success',
            timeout: 5000,
            text: this.$t('alerts.register_email_sent')
          })
        }).catch(e => {
          this.$refs.alert.showAlert({
            type: 'error',
            timeout: 5000,
            text: this.$t('alerts.user_email_exists')
          })
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.link-wrapper {
  line-height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox-text {
  white-space: break-spaces;
}
.field-star {
  margin-right: 2px;
}
.agreement-text {
  font-size: 0.85em;
  .el-link {
    font-size: inherit;
  }
}
.ebejshij-link {
  font-size: 0.9em;
}
</style>
